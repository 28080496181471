var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "注册证信息", name: "registInfo" },
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "产品信息", name: "productInfo" },
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "单一规格", name: "singleSpec" },
          }),
          _vm._v(" "),
          _vm.isprice !== "1"
            ? [
                _c("el-tab-pane", {
                  attrs: { label: "产品目录", name: "prodCata" },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(_vm.activeName, {
        tag: "component",
        attrs: { "prod-id": _vm.prodId },
        on: { setProdId: _vm.setProdId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }