var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentVisible
    ? _c("div", { staticClass: "PDF_dialog_box" }, [
        _c(
          "div",
          { staticClass: "PDF_dialog_close", on: { click: _vm.close } },
          [_vm._v("\n        关闭\n    ")]
        ),
        _vm._v(" "),
        _c("iframe", {
          attrs: {
            id: "framework",
            title: "framework",
            width: "100%",
            height: "100%",
            frameborder: "0",
            scrolling: "no",
            marginheight: "0",
            marginwidth: "0",
            src: _vm.pdfUrl,
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }