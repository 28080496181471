export default {
    baseUrl: 'https://pre-api.bqex.com/code',
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 服务集合
    // baseOss: {
    //     '/base': 'http://hxbase.heisea.cn/'
    // },
    // 上传
    filePath: 'https://pre-api.bqex.com/code/base/fe/info', // 文件信息
    uploadPath: 'https://pre-api.bqex.com/code/base/fe/upload', // 上传
    batchFilePath: 'https://pre-api.bqex.com/code/base/fe/batchInfo', // 批量获取文件信息
    downloadPath: 'https://pre-api.bqex.com/code/base/fe/download'
};
