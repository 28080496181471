var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c(
        "keep-alive",
        { ref: "keep" },
        [
          _vm.$route.meta.display !== "none"
            ? _c("router-view", { ref: "page" })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.meta.display === "none" ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }