var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_c("bk-form", { ref: "form", attrs: { "form-config": _vm.formConfig } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }